import { atom } from "recoil";

export const videoPlayer = atom({
  key: "videoPlayer",
  default: 0,
});

export const videoPlayerAtom = atom({
  key: 'videoPlayerAtom',
  default: 0,
});
