import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { imageEditorAtom } from '../atom/imageEditor';
import { Win95Button } from '../Win95Button';

const ImageEditor: React.FC = () => {
  const [imageEditor] = useRecoilState(imageEditorAtom);

  const handleSave = () => {
    if (imageEditor.image) {
      const link = document.createElement('a');
      link.href = imageEditor.image;
      link.download = 'rebel-creation.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <EditorContainer>
      {imageEditor.image && (
        <>
          <EditorImage src={imageEditor.image} alt="Rebel Creation" />
          <Win95Button onClick={handleSave}>Save Image</Win95Button>
        </>
      )}
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #c0c0c0;
  border: 2px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  width: 90%;
  height: 90%;
  overflow: auto;
`;

const EditorImage = styled.img`
  max-width: 100%;
  max-height: calc(100% - 40px); // Adjust based on your layout
  object-fit: contain;
  margin-bottom: 20px;
`;

export default ImageEditor;