import { atom } from "recoil";

export const freecellAtom = atom({
  key: "freecellAtom",
  default: 0,
});

export const displayFreecell = atom({
  key: "displayFreecell",
  default: false,
});