import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { walletConnectedAtom, walletAddressAtom } from '../atom/wallet';
import { xumm } from '../../xummConfig';

// Update this type to match the actual return type of xumm.authorize()
type XummAuthResult = {
  jwt: string;
  me: {
    account?: string;
    [key: string]: any;
  };
  sdk: any;
};

const WalletConnectButton = () => {
  const [isConnected, setIsConnected] = useRecoilState(walletConnectedAtom);
  const [, setWalletAddress] = useRecoilState(walletAddressAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = async () => {
    if (isConnected) {
      // Disconnect wallet
      setIsConnected(false);
      setWalletAddress('');
      return;
    }

    setIsLoading(true);
    try {
      console.log('Attempting to authorize with XUMM...');
      const result = await xumm.authorize() as XummAuthResult;
      console.log('XUMM authorization result:', result);

      if (result && result.me) {
        if (result.me.account) {
          console.log('Account found:', result.me.account);
          setIsConnected(true);
          setWalletAddress(result.me.account);
        } else {
          console.error('No account returned in me object. Full result:', result);
          throw new Error('No account returned from authorization');
        }
      } else {
        console.error('Invalid result structure. Full result:', result);
        throw new Error('Invalid authorization result structure');
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      alert(`Failed to connect wallet: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconWindows onClick={handleConnect} disabled={isLoading}>
      <Img src="/icons/keys.png" alt="Connect Wallet" />
      <Label>
        {isLoading ? 'Connecting...' : isConnected ? 'Disconnect' : 'Connect Wallet'}
      </Label>
    </IconWindows>
  );
};

const IconWindows = styled.div<{ disabled: boolean }>`
  text-decoration: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  display: flex;
  width: 63px;
  height: 63px;
  margin: 0px 30px 30px 30px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
`;

const Img = styled.img`
  width: 80%;
  height: 80%;
`;

const Label = styled.div`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;

export default WalletConnectButton;