import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { imageEditorAtom } from "../atom/imageEditor";
import { Win95Button } from '../Win95Button';

interface IAttributes {
  backgrounds: [{ name: string }];
  scribbles: [{ name: string }];
  heads: [{ name: string }];
  tattoos: [{ name: string }];
  skins: [{ name: string }];
  tops: [{ name: string }];
  baseUrl: [{ name: string }];
  extention: string;
  pathTraits: {
    background: string;
    scribble: string;
    tattoo: string;
    skin: string;
    head: string;
    top: string;
  };
}

interface IState {
  background: string;
  scribble: string;
  skin: string;
  tattoo: string;
  top: string;
  head: string;
}

const initialState: IState = {
  background: "Gradient-5",
  scribble: "Scribble - Old Wall",
  skin: "Gradient",
  tattoo: "Tattoo - Car Parts",
  top: "Vest - Purple",
  head: "Parking Meter - Classic",
};

const SelectInput = React.memo(({ label, options, value, onChange }: {
  label: string;
  options: { name: string }[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => (
  <div className="selector">
    <div className="label">{label}: </div>
    <select value={value} onChange={onChange}>
      {options.map((e) => (
        <option key={e.name} value={e.name.replaceAll(" ", "%20")}>
          {e.name}
        </option>
      ))}
    </select>
  </div>
));

export const Builder = () => {
  const [state, setState] = useState<IState>(initialState);
  const [attributes, setAttributes] = useState<IAttributes>();
  const preloadedImages = useRef<Record<string, HTMLImageElement>>({});
  const [isLoading, setIsLoading] = useState(true);
  const loadedImagesCount = useRef(0);

  const setImageEditor = useSetRecoilState(imageEditorAtom);

  useEffect(() => {
    getData().then(setAttributes);
  }, []);

  useEffect(() => {
    if (attributes) {
      setIsLoading(true);
      loadedImagesCount.current = 0;
      const totalImages = Object.keys(state).length;
      
      const { baseUrl, extention, pathTraits } = attributes;
      Object.entries(state).forEach(([key, value]) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `${baseUrl}${pathTraits[key as keyof typeof pathTraits]}/${value}.${extention}`;
        img.onload = () => {
          preloadedImages.current[`${key}-${value}`] = img;
          loadedImagesCount.current += 1;
          if (loadedImagesCount.current === totalImages) {
            setIsLoading(false);
          }
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${key}-${value}`);
          loadedImagesCount.current += 1;
          if (loadedImagesCount.current === totalImages) {
            setIsLoading(false);
          }
        };
      });
    }
  }, [attributes, state]);

  const handleChange = useCallback((key: keyof IState) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState(prev => ({ ...prev, [key]: event.target.value }));
  }, []);

  const sortedAttributes = useMemo(() => {
    if (!attributes) return null;
    const {
      backgrounds,
      scribbles,
      heads,
      tattoos,
      skins,
      tops,
    } = attributes;
    return {
      backgrounds: [...backgrounds].sort((a, b) => a.name.localeCompare(b.name)),
      scribbles: [...scribbles].sort((a, b) => a.name.localeCompare(b.name)),
      heads: [...heads].sort((a, b) => a.name.localeCompare(b.name)),
      tops: [...tops].sort((a, b) => a.name.localeCompare(b.name)),
      skins: [...skins].sort((a, b) => a.name.localeCompare(b.name)),
      tattoos: [...tattoos].sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [attributes]);

  const gatherLayers = useCallback(() => {
    if (!attributes) return;

    setIsGathering(true);
    setGatherProgress(0);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Find the first valid image to set canvas size
    const firstValidImage = Object.values(preloadedImages.current).find(img => img && img.complete);
    if (!firstValidImage) {
      console.error('No valid images found');
      setIsGathering(false);
      return;
    }

    canvas.width = firstValidImage.naturalWidth;
    canvas.height = firstValidImage.naturalHeight;

    if (ctx) {
      const totalImages = Object.entries(state).length;

      const drawNextImage = (index: number) => {
        if (index >= totalImages) {
          const dataUrl = canvas.toDataURL('image/png');
          setImageEditor({ image: dataUrl, isOpen: true });
          setIsGathering(false);
          setGatherProgress(0);
          return;
        }

        const [key, value] = Object.entries(state)[index];
        const img = preloadedImages.current[`${key}-${value}`];

        if (!img) {
          console.error(`Image not found for ${key}-${value}`);
          drawNextImage(index + 1);
          return;
        }

        const drawAndContinue = () => {
          try {
            ctx.drawImage(img, 0, 0);
          } catch (error) {
            console.error(`Error drawing image for ${key}-${value}:`, error);
          }
          setGatherProgress(Math.round(((index + 1) / totalImages) * 100));
          setTimeout(() => drawNextImage(index + 1), 0);
        };

        if (img.complete) {
          drawAndContinue();
        } else {
          img.onload = drawAndContinue;
          img.onerror = () => {
            console.error(`Failed to load image for ${key}-${value}`);
            drawNextImage(index + 1);
          };
        }
      };

      drawNextImage(0);
    }
  }, [state, attributes, setImageEditor]);

  const [gatherProgress, setGatherProgress] = useState<number>(0);
  const [isGathering, setIsGathering] = useState<boolean>(false);

  if (!attributes || !sortedAttributes) return <div>loading</div>;

  const {
    baseUrl,
    extention,
    pathTraits,
  } = attributes;

  return (
    <Container>
      <div className="layout">
        {Object.entries(state).map(([key, value]) => (
          <img
            key={key}
            src={`${baseUrl}${pathTraits[key as keyof typeof pathTraits]}/${value}.${extention}`}
            alt={key}
          />
        ))}
      </div>
      <div>
        <h2>Options</h2>
        <SelectInput label="Background" options={sortedAttributes.backgrounds} value={state.background} onChange={handleChange('background')} />
        <SelectInput label="Scribble" options={sortedAttributes.scribbles} value={state.scribble} onChange={handleChange('scribble')} />
        <SelectInput label="Skin" options={sortedAttributes.skins} value={state.skin} onChange={handleChange('skin')} />
        <SelectInput label="Tattoo" options={sortedAttributes.tattoos} value={state.tattoo} onChange={handleChange('tattoo')} />
        <SelectInput label="Tops" options={sortedAttributes.tops} value={state.top} onChange={handleChange('top')} />
        <SelectInput label="Head" options={sortedAttributes.heads} value={state.head} onChange={handleChange('head')} />
      </div>
      <ButtonContainer>
        <Win95Button onClick={gatherLayers} disabled={isLoading || isGathering}>
          {isLoading ? 'Loading Layers...' : isGathering ? 'Gathering...' : 'Gather Layers to Save'}
        </Win95Button>
        {isGathering && (
          <>
            <ProgressBar progress={gatherProgress} />
            <div>{gatherProgress}% complete</div>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  padding: 10px;
  display: flex;
  max-width: 50vw;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  margin: 5px;
  color: black;
  @media (max-width: 415px) {
    width: 320px;
  }
  h2 {
    padding-left: 10px;
  }
  .layout {
    position: relative;
    width: 400px;
    height: 400px;
    @media (max-width: 415px) {
      width: 320px;
      height: 320px;
    }

    img {
      position: absolute;
      width: 400px;
      height: 400px;
      @media (max-width: 415px) {
        width: 320px;
        height: 320px;
      }
    }
  }
  .selector {
    padding-left: 10px;
    display: flex;
    .label {
      width: 30%;
    }
    select {
      width: 50%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 20px;
  background-color: #c0c0c0;
  border: 2px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  margin-top: 10px;

  &::after {
    content: '';
    display: block;
    width: ${props => props.progress}%;
    height: 100%;
    background-color: #008080;
    transition: width 0.3s ease;
  }
`;

const getData = () => {
  return fetch("data/attributes.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then((e) => {
      return e as IAttributes;
    });
};

export default Builder;