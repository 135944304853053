import { atom } from 'recoil';

export const walletAddressAtom = atom<string>({
  key: 'walletAddress',
  default: '',
});

export const walletConnectedAtom = atom<boolean>({
  key: 'walletConnected',
  default: false,
});

// If you need a walletAtom as well, you can add it like this:
export const walletAtom = atom({
  key: 'walletAtom',
  default: null, // or any default value you prefer
});