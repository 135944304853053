import React, { useState, useRef, useEffect } from 'react';
import Draggable from "react-draggable";
import styled from "styled-components";

interface IWindows {
  setClose: Function;
  title: String;
  width?: number | string;
  height?: number | string;
  x?: number | string;
  y?: number | string;
  margin?: string;
  isPhone?: Boolean;
  isDisplay?: Boolean;
  isResizable?: Boolean;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
}

export const Windows: React.FC<IWindows> = (props) => {
  const isMobile = props.isPhone ? (props.isPhone as boolean) : false;
  const [size, setSize] = useState({ 
    width: props.width || 'auto', 
    height: props.height || 'auto' 
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const windowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateSize = () => {
      if (contentRef.current && windowRef.current) {
        const contentWidth = contentRef.current.scrollWidth;
        const contentHeight = contentRef.current.scrollHeight;
        const titleBarHeight = 21; // Adjust this value based on your BarWindows height

        setSize({
          width: props.width || `${contentWidth + 20}px`, // 20px for padding
          height: props.height || `${contentHeight + titleBarHeight + 10}px` // 10px for padding
        });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [props.children, props.width, props.height]);

  const handleResize = (e: React.MouseEvent) => {
    if (!props.isResizable) return;

    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = windowRef.current?.offsetWidth || 0;
    const startHeight = windowRef.current?.offsetHeight || 0;

    const doDrag = (e: MouseEvent) => {
      setSize({
        width: `${startWidth + e.clientX - startX}px`,
        height: `${startHeight + e.clientY - startY}px`
      });
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', doDrag);
      document.removeEventListener('mouseup', stopDrag);
    };

    document.addEventListener('mousemove', doDrag);
    document.addEventListener('mouseup', stopDrag);
  };

  return (
    <Draggable handle=".handle" disabled={isMobile}>
      <WindowsMedia
        ref={windowRef}
        margin={props.margin}
        isPhone={props.isPhone}
        isDisplay={props.isDisplay}
        style={{
          width: size.width,
          height: size.height,
          minWidth: props.minWidth || '200px',
          minHeight: props.minHeight || '100px',
          maxWidth: props.maxWidth || '90vw',
          maxHeight: props.maxHeight || '90vh',
        }}
      >
        <BarWindows className="handle">
          <ContentTitle>
            <Title>{props.title}</Title>
          </ContentTitle>
          <Close
            onClick={() => props.setClose(false)}
            isActive={!props.isPhone}
          >
            <Img src="icons/close.png" alt="Close" />
          </Close>
        </BarWindows>
        <Content ref={contentRef}>{props.children}</Content>
        {props.isResizable && <ResizeHandle onMouseDown={handleResize} />}
      </WindowsMedia>
    </Draggable>
  );
};

const WindowsMedia = styled.div<{
  margin?: String;
  isPhone?: Boolean;
  isDisplay?: Boolean;
}>`
  overflow: hidden;
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background: #c2c6ca;
  font-size: 16px;
  -webkit-font-smoothing: none;
  box-shadow: 0.5px 0.5px 0 0.5px black, inset 1px 1px white,
    inset -1px -1px black, inset 2px 2px white;
  position: ${(props) => !props.isPhone && "absolute"};
  z-index: 10;
  margin: ${({ margin }) => (!margin ? "auto" : margin + "")};
  user-select: none;
`;

const BarWindows = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: bold;
  width: 99%;
  height: 16px;
  color: white;
  background: #00a;
  padding: 3px 0;
  margin: 2px 3px 0 3px;
  align-items: center;
`;

const Close = styled.button<{ isActive: Boolean }>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  text-decoration: none;
  border: 1px solid black;
  background-color: transparent;
  padding: 0;
  z-index: 10;
`;

const ContentTitle = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`;

const Title = styled.div`
  padding: 0 10px;
`;

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3px;
  box-sizing: border-box;
`;

const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  background: transparent;
  user-select: none;
`;

const Img = styled.img``;

export default Windows;