import styled from "styled-components";
import { useRecoilState } from "recoil";
import * as Atom from "./atom";
import { discordUrl, twitterUrl } from "../constants";

export const ProjectInfoFolder = () => {
  const [, setRoadmap] = useRecoilState(Atom.roadmapAtom);
  const [, setAboutWindow] = useRecoilState(Atom.aboutAtom);

  return (
    <Container>
      <Content>
        <ContainerIcon>
          <IconWindows onClick={() => window.open(twitterUrl, "_blank")}>
            <Img src="icons/twitter.png" />
            <Label>Twitter</Label>
          </IconWindows>
          <IconWindows onClick={() => window.open(discordUrl, "_blank")}>
            <Img src="icons/discord.png" />
            <Label>Discord</Label>
          </IconWindows>
          <IconWindows onClick={() => setRoadmap(1)}>
            <Img src="/icons/windows98-icons/png/tip.png" />
            <Label>About Sunrebels</Label>
          </IconWindows>
        </ContainerIcon>
        <ContainerIcon>
          <IconWindows onClick={() => setAboutWindow(1)}>
            <Img src="icons/about.png" />
            <Label>About Sorriso</Label>
          </IconWindows>
        </ContainerIcon>
      </Content>
    </Container>
  );
};

const ContainerIcon = styled.div`
  display: flex;
  margin-top: 10px;
`;

const IconWindows = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 42px;
  height: 42px;
  margin: 0px 30px 30px 30px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
`;

const Label = styled.div`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: black;
`;

const Img = styled.img`
  width: 80%;
  height: 80%;
`;

const Content = styled.div`
  display: flex;
  width: 360px;
  height: 200px;
  margin: 5px;
  background-color: white;
  flex-direction: column;
  @media (max-width: 415px) {
    align-items: center;
    height: fit-content;
  }
`;

const Container = styled.section`
  max-width: 50vh;
  border: 1px solid black;
  @media (max-width: 415px) {
    overflow: scroll;
    flex-direction: column;
    align-items: center;
  }
`;