import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const AdvancedMSPaint: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState('pencil');
  const [color, setColor] = useState('#000000');
  const [fillColor, setFillColor] = useState('#FFFFFF');
  const [size, setSize] = useState(1);
  const [prevPos, setPrevPos] = useState<{ x: number; y: number } | null>(null);
  const [text, setText] = useState('');
  const [textPos, setTextPos] = useState<{ x: number; y: number } | null>(null);
  const [memeMode, setMemeMode] = useState(false);
  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const [savedImageData, setSavedImageData] = useState<ImageData | null>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });

  const colors = [
    '#000000', '#808080', '#800000', '#808000', '#008000', '#008080', '#000080', '#800080',
    '#FFFFFF', '#C0C0C0', '#FF0000', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#FF00FF'
  ];

  const sizes = [1, 2, 4, 6, 8, 10, 12, 14];

  useEffect(() => {
    const updateCanvasSize = () => {
      const width = Math.min(window.innerWidth - 240, 600);
      const height = Math.min(window.innerHeight - 200, 600);
      setCanvasSize({ width, height });
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d', { willReadFrequently: true });
      if (context) {
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.fillStyle = '#FFFFFF';
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
    }
  }, [canvasSize]);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setPrevPos({ x, y });

      const context = canvas.getContext('2d', { willReadFrequently: true });
      if (context) {
        context.beginPath();
        context.moveTo(x, y);
      }

      if (tool === 'fill') {
        floodFill(x, y, fillColor);
      } else if (tool === 'text') {
        setTextPos({ x, y });
      }
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    setPrevPos(null);
    if (tool === 'text' && textPos) {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d', { willReadFrequently: true });
      if (context && canvas) {
        context.font = `${size * 5}px Arial`;
        context.fillStyle = color;
        context.fillText(text, textPos.x, textPos.y);
      }
      setTextPos(null);
    }
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (context && canvas) {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      context.strokeStyle = color;
      context.lineWidth = size;

      if (tool === 'pencil' || tool === 'brush') {
        context.lineTo(x, y);
        context.stroke();
      } else if (tool === 'eraser') {
        context.strokeStyle = '#FFFFFF';
        context.lineTo(x, y);
        context.stroke();
      } else if (tool === 'spray') {
        for (let i = 0; i < size * 10; i++) {
          const offsetX = (Math.random() - 0.5) * size * 2;
          const offsetY = (Math.random() - 0.5) * size * 2;
          context.fillStyle = color;
          context.fillRect(x + offsetX, y + offsetY, 1, 1);
        }
      } else if (tool === 'line' && prevPos) {
        if (savedImageData) context.putImageData(savedImageData, 0, 0);
        context.beginPath();
        context.moveTo(prevPos.x, prevPos.y);
        context.lineTo(x, y);
        context.stroke();
      } else if (tool === 'rectangle' && prevPos) {
        if (savedImageData) context.putImageData(savedImageData, 0, 0);
        const width = x - prevPos.x;
        const height = y - prevPos.y;
        context.strokeRect(prevPos.x, prevPos.y, width, height);
      } else if (tool === 'circle' && prevPos) {
        if (savedImageData) context.putImageData(savedImageData, 0, 0);
        const radius = Math.sqrt(Math.pow(x - prevPos.x, 2) + Math.pow(y - prevPos.y, 2));
        context.beginPath();
        context.arc(prevPos.x, prevPos.y, radius, 0, 2 * Math.PI);
        context.stroke();
      }
    }
  };

  const floodFill = (x: number, y: number, fillColor: string) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });
    if (!ctx || !canvas) return;

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const targetColor = getPixel(imageData, x, y);
    const fillColorRgb = hexToRgb(fillColor);

    if (!fillColorRgb) return;

    const pixelsToCheck = [{x, y}];
    const maxPixels = canvas.width * canvas.height;
    let pixelsCovered = 0;

    while (pixelsToCheck.length > 0 && pixelsCovered < maxPixels) {
      const {x, y} = pixelsToCheck.pop()!;
      if (x < 0 || x >= canvas.width || y < 0 || y >= canvas.height) continue;
      if (!compareColor(getPixel(imageData, x, y), targetColor)) continue;

      setPixel(imageData, x, y, fillColorRgb);
      pixelsCovered++;

      pixelsToCheck.push({x: x + 1, y}, {x: x - 1, y}, {x, y: y + 1}, {x, y: y - 1});
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const getPixel = (imageData: ImageData, x: number, y: number) => {
    const index = (y * imageData.width + x) * 4;
    return {
      r: imageData.data[index],
      g: imageData.data[index + 1],
      b: imageData.data[index + 2],
      a: imageData.data[index + 3]
    };
  };

  const setPixel = (imageData: ImageData, x: number, y: number, color: {r: number, g: number, b: number}) => {
    const index = (y * imageData.width + x) * 4;
    imageData.data[index] = color.r;
    imageData.data[index + 1] = color.g;
    imageData.data[index + 2] = color.b;
    imageData.data[index + 3] = 255;
  };

  const compareColor = (color1: {r: number, g: number, b: number, a: number}, color2: {r: number, g: number, b: number, a: number}) => {
    return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a === color2.a;
  };

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (context && canvas) {
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
  };

  const saveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'ms-paint-drawing.png';
      link.href = image;
      link.click();
    }
  };

  const loadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = canvasRef.current;
          const context = canvas?.getContext('2d', { willReadFrequently: true });
          if (context && canvas) {
            const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
            const x = (canvas.width / 2) - (img.width / 2) * scale;
            const y = (canvas.height / 2) - (img.height / 2) * scale;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(img, x, y, img.width * scale, img.height * scale);
          }
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const createMeme = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (context && canvas) {
      const fontSize = canvas.height / 10;
      context.font = `bold ${fontSize}px Impact`;
      context.fillStyle = 'white';
      context.strokeStyle = 'black';
      context.lineWidth = fontSize / 15;
      context.textAlign = 'center';

      // Top text
      context.textBaseline = 'top';
      context.fillText(topText.toUpperCase(), canvas.width / 2, fontSize / 2);
      context.strokeText(topText.toUpperCase(), canvas.width / 2, fontSize / 2);

      // Bottom text
      context.textBaseline = 'bottom';
      context.fillText(bottomText.toUpperCase(), canvas.width / 2, canvas.height - fontSize / 2);
      context.strokeText(bottomText.toUpperCase(), canvas.width / 2, canvas.height - fontSize / 2);
    }
  };

  const saveCanvasState = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (context && canvas) {
      setSavedImageData(context.getImageData(0, 0, canvas.width, canvas.height));
    }
  };

  return (
    <PaintContainer>
      <TitleBar>Rebel Paint & Meme Maker</TitleBar>
      <MenuBar>
        <MenuItem>File</MenuItem>
        <MenuItem>Edit</MenuItem>
        <MenuItem>View</MenuItem>
        <MenuItem>Image</MenuItem>
        <MenuItem>Options</MenuItem>
        <MenuItem>Help</MenuItem>
      </MenuBar>
      <TopToolBar>
        <ActionButton onClick={saveImage}>💾 Save</ActionButton>
        <ActionButton as="label">
          📂 Load
          <input type="file" accept="image/*" onChange={loadImage} style={{ display: 'none' }} />
        </ActionButton>
        <ActionButton onClick={() => setMemeMode(!memeMode)}>🎭 Meme {memeMode ? 'On' : 'Off'}</ActionButton>
      </TopToolBar>
      <ContentArea>
        <ToolBar>
          <ToolGrid>
            <ToolButton onClick={() => setTool('pencil')} active={tool === 'pencil'}>✏️</ToolButton>
            <ToolButton onClick={() => setTool('brush')} active={tool === 'brush'}>🖌️</ToolButton>
            <ToolButton onClick={() => setTool('spray')} active={tool === 'spray'}>🎨</ToolButton>
            <ToolButton onClick={() => setTool('eraser')} active={tool === 'eraser'}>🧽</ToolButton>
            <ToolButton onClick={() => setTool('fill')} active={tool === 'fill'}>🪣</ToolButton>
            <ToolButton onClick={() => setTool('text')} active={tool === 'text'}>T</ToolButton>
            <ToolButton onClick={() => { setTool('line'); saveCanvasState(); }} active={tool === 'line'}>📏</ToolButton>
            <ToolButton onClick={() => { setTool('rectangle'); saveCanvasState(); }} active={tool === 'rectangle'}>🔲</ToolButton>
            <ToolButton onClick={() => { setTool('circle'); saveCanvasState(); }} active={tool === 'circle'}>⭕</ToolButton>
            <ToolButton onClick={clearCanvas}>🧹</ToolButton>
          </ToolGrid>
          <SizeButtons>
            {sizes.map(s => (
              <SizeButton key={s} onClick={() => setSize(s)} active={size === s}>
                
                {s}
              </SizeButton>
            ))}
          </SizeButtons>
          {tool === 'text' && (
            <TextInput
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter text"
            />
          )}
        </ToolBar>
        <CanvasArea>
          <Canvas
            ref={canvasRef}
            width={canvasSize.width}
            height={canvasSize.height}
            onMouseDown={startDrawing}
            onMouseUp={stopDrawing}
            onMouseMove={draw}
            onMouseLeave={stopDrawing}
          />
          {memeMode && (
            <MemeControls>
              <TextInput
                value={topText}
                onChange={(e) => setTopText(e.target.value)}
                placeholder="Top Text"
              />
              <TextInput
                value={bottomText}
                onChange={(e) => setBottomText(e.target.value)}
                placeholder="Bottom Text"
              />
              <ActionButton onClick={createMeme}>Create Meme</ActionButton>
            </MemeControls>
          )}
        </CanvasArea>
      </ContentArea>
      <ColorPalette>
        {colors.map((c) => (
          <ColorButton key={c} color={c} onClick={() => setColor(c)} active={color === c} />
        ))}
        <ColorPicker type="color" value={color} onChange={(e) => setColor(e.target.value)} />
        <FillColorPicker type="color" value={fillColor} onChange={(e) => setFillColor(e.target.value)} />
      </ColorPalette>
    </PaintContainer>
  );
};

const PaintContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  border: 2px solid #dfdfdf;
  border-right-color: #808080;
  border-bottom-color: #808080;
  width: 100%;
  height: 100%;
  font-family: 'MS Sans Serif', Arial, sans-serif;
`;

const TitleBar = styled.div`
  background-color: #000080;
  color: white;
  padding: 2px 4px;
  font-weight: bold;
`;

const MenuBar = styled.div`
  display: flex;
  background-color: #c0c0c0;
  border-bottom: 1px solid #808080;
  padding: 2px 0;
`;

const MenuItem = styled.div`
  padding: 2px 6px;
  cursor: default;
  &:hover {
    background-color: #000080;
    color: white;
  }
`;

const TopToolBar = styled.div`
  display: flex;
  padding: 4px;
  background-color: #c0c0c0;
  border-bottom: 1px solid #808080;
`;

const ContentArea = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const ToolBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #c0c0c0;
  border-right: 1px solid #808080;
  width: 66px;
`;

const ToolGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
`;

const ToolButton = styled.button<{ active?: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${props => props.active ? '#808080' : '#c0c0c0'};
  border: 1px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  &:active {
    border: 1px solid #808080;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
  }
`;

const SizeButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  margin-top: 4px;
`;

const SizeButton = styled.button<{ active?: boolean }>`
  width: 30px;
  height: 20px;
  background-color: ${props => props.active ? '#808080' : '#c0c0c0'};
  border: 1px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 0;
  &:active {
    border: 1px solid #808080;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
  }
`;

const TextInput = styled.input`
  width: 90%;
  margin-top: 4px;
  font-size: 12px;
`;

const ActionButton = styled.button`
  margin-right: 4px;
  padding: 2px 6px;
  background-color: #c0c0c0;
  border: 1px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  cursor: pointer;
  font-size: 12px;
  &:active {
    border: 1px solid #808080;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
  }
`;

const CanvasArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #808080;
`;

const Canvas = styled.canvas`
  background-color: white;
  cursor: crosshair;
  border: 1px solid #808080;
`;

const ColorPalette = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px;
  background-color: #c0c0c0;
  border-top: 1px solid #808080;
`;

const ColorButton = styled.button<{ color: string; active?: boolean }>`
  width: 16px;
  height: 16px;
  margin: 0 1px;
  background-color: ${props => props.color};
  border: 1px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  ${props => props.active && `
    border: 1px solid #000000;
  `}
  cursor: pointer;
`;

const ColorPicker = styled.input`
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  background: none;
  margin-left: 2px;
`;

const FillColorPicker = styled(ColorPicker)`
  margin-left: 2px;
`;

const MemeControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export default AdvancedMSPaint;