import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { xumm } from '../../xummConfig';
import { Xumm } from 'xumm';
import { XummTypes } from 'xumm-sdk';
import { isValidClassicAddress } from 'ripple-address-codec';

export const VanityGenerator: React.FC = () => {
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [result, setResult] = useState<{ address: string; seed: string } | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isVerifiedHolder, setIsVerifiedHolder] = useState<boolean>(false);
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const workerRef = useRef<Worker | null>(null);

  const generateAddress = useCallback(() => {
    setIsGenerating(true);
    setResult(null);
    setEstimatedTime(null);

    workerRef.current = new Worker(new URL('./vanityWorker.ts', import.meta.url));

    workerRef.current.onmessage = (event) => {
      if (event.data.address) {
        setResult(event.data);
        setIsGenerating(false);
        workerRef.current?.terminate();
        workerRef.current = null;
      } else if (event.data.estimatedTime) {
        setEstimatedTime(event.data.estimatedTime);
      }
    };

    workerRef.current.postMessage({ prefix, suffix });
  }, [prefix, suffix]);

  const cancelGeneration = useCallback(() => {
    if (workerRef.current) {
      workerRef.current.terminate();
      workerRef.current = null;
      setIsGenerating(false);
      setEstimatedTime(null);
    }
  }, []);

  const saveAddress = useCallback(() => {
    if (result) {
      const blob = new Blob([JSON.stringify(result)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'vanity_address.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }, [result]);

  const importToWallet = useCallback(async () => {
    if (!result) return;

    setIsImporting(true);
    try {
      console.log('XUMM SDK configuration:', {
        environment: xumm.environment,
        isInitialized: !!xumm.payload,
      });

      if (!xumm.payload) {
        console.error('XUMM SDK is not properly initialized');
        throw new Error('XUMM SDK is not properly initialized');
      }

      console.log('Refreshing XUMM SDK authorization...');
      try {
        await xumm.authorize();
        console.log('XUMM SDK authorization refreshed successfully');
      } catch (authError) {
        console.error('Failed to refresh XUMM SDK authorization:', authError);
        throw new Error('Failed to authenticate with XUMM');
      }

      console.log('Creating SignIn request...');
      const signInRequest = await xumm.payload.create({
        TransactionType: 'SignIn'
      });

      if (!signInRequest || !signInRequest.uuid) {
        throw new Error('Failed to create SignIn request');
      }

      console.log('SignIn request created:', signInRequest);
      window.open(signInRequest.next.always);

      console.log('Waiting for user to approve SignIn...');
      
      const maxAttempts = 60;
      let attempts = 0;
      let signInPayload;

      while (attempts < maxAttempts) {
        signInPayload = await xumm.payload.get(signInRequest.uuid);
        console.log('Checking SignIn status:', signInPayload);
        if (signInPayload && signInPayload.meta.signed) {
          console.log('SignIn approved.');
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        attempts++;
      }

      if (!signInPayload || !signInPayload.meta.signed) {
        throw new Error('SignIn request timed out or was not approved');
      }

      console.log('SignIn payload:', JSON.stringify(signInPayload, null, 2));

      console.log('Creating SetRegularKey request...');
      const setRegularKeyPayload: XummTypes.CreatePayload = {
        txjson: {
          TransactionType: 'SetRegularKey',
          Account: signInPayload.response.account,
          RegularKey: result.address
        }
      };

      console.log('SetRegularKey payload being sent:', JSON.stringify(setRegularKeyPayload, null, 2));

      const importRequest = await xumm.payload.create(setRegularKeyPayload);
      
      if (!importRequest || !importRequest.uuid) {
        throw new Error('Failed to create SetRegularKey request');
      }

      console.log('SetRegularKey request created:', importRequest);
      window.open(importRequest.next.always);
      alert('Please approve the SetRegularKey transaction in your XUMM wallet to import the vanity address.');

    } catch (error) {
      console.error('Error in import process:', error);
      if (error instanceof Error) {
        console.error('Error details:', error.message);
        console.error('Error stack:', error.stack);
      }
      if (typeof error === 'object' && error !== null) {
        console.error('Full error object:', JSON.stringify(error, null, 2));
      }
      throw new Error(`Failed to import address: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsImporting(false);
    }
  }, [result, xumm]);

  const checkNFTHolding = useCallback(async (address: string) => {
    try {
      const response = await fetch(`https://xrplexplorer.com/api/v2/nfts?owner=${address}&issuer=rNBoiDuQfDgr4HRrCErB7CPE2qrDt2836D&taxon=1`, {
        headers: {
          'x-bithomp-token': process.env.REACT_APP_BITHOMP_API_KEY || ''
        }
      });
      const data = await response.json();
      
      setIsVerifiedHolder(data.nfts && data.nfts.length > 0);
    } catch (error) {
      console.error('Error checking NFT holding:', error);
      setIsVerifiedHolder(false);
    }
  }, []);

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const address = await xumm.user.account;
        if (address) {
          setUserAddress(address);
          checkNFTHolding(address);
        } else {
          setUserAddress(null);
          setIsVerifiedHolder(false);
        }
      } catch (error) {
        console.error('Error fetching user address:', error);
        setUserAddress(null);
        setIsVerifiedHolder(false);
      }
    };

    fetchUserAddress();
  }, [checkNFTHolding]);

  return (
    <Container>
      <Title>XRP Ledger Vanity Address Generator</Title>
      {!userAddress ? (
        <Button onClick={() => xumm.authorize()}>Connect Wallet</Button>
      ) : !isVerifiedHolder ? (
        <p>Sorry, this tool is only available to holders of the required NFT.</p>
      ) : (
        <>
          <InputGroup>
            <label>
              Prefix:
              <input
                type="text"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value.toUpperCase())}
                placeholder="e.g., ABC"
              />
            </label>
          </InputGroup>
          <InputGroup>
            <label>
              Suffix:
              <input
                type="text"
                value={suffix}
                onChange={(e) => setSuffix(e.target.value.toUpperCase())}
                placeholder="e.g., XRP"
              />
            </label>
          </InputGroup>
          <Button onClick={generateAddress} disabled={isGenerating}>
            {isGenerating ? 'Generating...' : 'Generate Address'}
          </Button>
          {isGenerating && (
            <Button onClick={cancelGeneration}>Cancel Generation</Button>
          )}
          {estimatedTime !== null && (
            <EstimatedTime>Estimated time remaining: {estimatedTime.toFixed(2)} seconds</EstimatedTime>
          )}
          {result && (
            <Result>
              <p>Public Address: {result.address}</p>
              <Warning>
                Warning: The private key is not displayed for security reasons. 
                Please save or import the address to access the private key.
              </Warning>
              <Button onClick={saveAddress}>Save Address</Button>
              <Button disabled={true}>
                Import to Wallet (coming soon)
              </Button>
            </Result>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 10px auto;
  padding: 20px;
  background-color: #c0c0c0;
  border: 2px solid #dfdfdf;
  border-right-color: #808080;
  border-bottom-color: #808080;
  box-shadow: inset 1px 1px 0 #ffffff, inset -1px -1px 0 #808080;
  font-family: 'MS Sans Serif', Arial, sans-serif;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 5px;
    color: #000000;
  }
  input {
    width: 80%;
    padding: 5px;
    border: 2px inset #fff;
    background-color: #ffffff;
    color: #000000;
  }
`;

const Button = styled.button`
  background-color: #c0c0c0;
  color: #000000;
  padding: 5px 10px;
  border: 2px solid #dfdfdf;
  border-right-color: #808080;
  border-bottom-color: #808080;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 100px;

  &:active {
    border: 2px solid #808080;
    border-right-color: #dfdfdf;
    border-bottom-color: #dfdfdf;
  }

  &:disabled {
    color: #808080;
    border-color: #c0c0c0;
    cursor: not-allowed;
  }
`;

const Result = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff;
  border: 2px inset #fff;
  color: #000000;
`;

const Title = styled.h2`
  color: #000080;
  text-align: center;
  margin-bottom: 20px;
`;

const EstimatedTime = styled.p`
  color: #000080;
  font-weight: bold;
`;

const Warning = styled.p`
  color: #ff0000;
  font-weight: bold;
  margin-top: 10px;
`;

export default VanityGenerator;