export const twitterUrl = "https://twitter.com/sunrebels";
export const discordUrl = "https://discord.gg/sunrebels";
export const xrpCafeUrl = "https://xrp.cafe/collection/rebels";

export const artistDatas = [
  {
    question: "How did you get started creating art?",
    answer:
      "I worked as a designer back in 2019, but I was so tired of it, I just wanted to escape so SORRISO was born! A place where I can create whatever I want and just express myself.",
  },
  {
    question: "Who inspires you?",
    answer:
      "Etiene Crauss (@etienecrauss) is definitely the person that influenced my art the most, not just in style but by believing in myself. I want to get to where he has got in his career.",
  },
  {
    question: "What does the creation of art mean to you?",
    answer:
      "In the very moment it means everything, art is my therapy, my escape from reality, my work, my happiness and stresses. I couldn’t live without creating.",
  },
  {
    question: "How would you describe your style of art?",
    answer:
      "It’s kind of hard to describe that, I take inspiration from so many places. I feel like my art is a blend from everything that I like + my feelings to drive the piece in that certain direction.",
  },
  {
    question: "What are your ultimate goals in this space?",
    answer:
      "I am trying not to think that far ahead, I like to focus on the moment and keep working, but I would love to be featured in a gallery someday, do a solo art show, work with the people that I admire and live off my creativity.",
  },
];

export const faqDatas = [
  {
    question: "XRPL NFT Information",
    answer: "The main wallet (mobile) on XRPL is https://xumm.app/",
  },
  {
    question: "Simple way to convert SOL to XRP",
    answer:
      "1. Download & Create wallet: https://xumm.app/ <br /> <br />2. (Browser) Open SimpleSwap website: https://simpleswap.io/exchange?from=sol&to=xrp <br /> <br />3. (Mobile) Open SimpleSwap in Xumm 'xApps' tab on the bottom: https://xumm.app/detect/xapp:onramp.simpleswap <br /> <br />4. Deposit SOL (at least 15 XRP to activate wallet), it automatically gets converted to XRP! <br /> <br />5. No need for destination tag since you own the wallet. Select 'I agree not to provide the destination tag.'",
  },
  {
    question: "Simple way to convert ETH to XRP",
    answer:
      "(Browser) Open SimpleSwap website: https://simpleswap.io/exchange?from=eth&to=xrp <br /> <br />(Mobile) Open SimpleSwap in Xumm 'xApps' tab on the bottom: https://xumm.app/detect/xapp:onramp.simpleswap <br /> <br />Deposit ETH (at least 15 XRP to activate wallet), it automatically gets converted to XRP! <br /> <br />No need for destination tag since you own the wallet. Select 'I agree not to provide the destination tag.'",
  },
  {
    question: "Top XRPL Marketplaces",
    answer:
      "- onXRP: https://nft.onxrp.com/ <br />- XRP Cafe: https://xrp.cafe/collection <br />- Sologenic: https://sologenic.org/ <br />- OnChainMarketplace: https://onchainmarketplace.net/ <br />- XMart: https://xmart.art/",
  },
  {
    question: "How to get Started and Deep Dive on XRP Info",
    answer:
      "Download Xumm Wallet! <br /> <br />(YouTube Video Tutorials listed below) <br /> <br />1. What is XUMM? https://www.youtube.com/watch?v=9sL63Uc0-7I <br /> <br />2. The XRP Ledger & XUMM: Where are your funds stored? https://www.youtube.com/watch?v=gLkVNm5Su1o <br /> <br />3. The XRP Ledger & XUMM: How can you access your funds? https://www.youtube.com/watch?v=m1OUr13_xv4 <br /> <br />4. The XRP Ledger & XUMM: Reserves and fees. https://www.youtube.com/watch?v=7E52W4dKmBE <br /> <br />5. The XRP Ledger & XUMM: Destination Tags. https://www.youtube.com/watch?v=gAWnIw4gzW8",
  },
  {
    question: "Notable Projects",
    answer:
      "The XRPL chain has a diverse selection of interesting projects which, as a newcomer, you should check out so that you can get an idea for the space.<br /><br />Notable Projects:<br/>• XPunks<br/>• Fortunes<br/>• Lux Lions<br/>These are some of our favourite projects who we feel are helping mold the future of Ripple NFTs.",
  },
  // {
  //   question: "So you're on Ethereum & Solana? How does that work?",
  //   answer:
  //     "Yep! 50% of the Sun Rebels supply will be minted on Solana and the other 50% will be minted on Ethereum. We will take time to build utilities to help bridge the two chains (e.g a joint rarity tool, a marketplace aggregator that pulls from SOL and ETH marketplaces etc.).</br>We decided to mint on both chains because we felt like the Ethereum market would love this project and we felt like this project deserved to see the mass-market. However, we also love Solana and we want to keep enriching the ocean of NFTs over there. With both of these points in mind, we decided to change the game and rather than choosing one over the other, we went for both. By being on both chains, we're not limiting ourselves to SOL investors or ETH investors.</br>",
  // },
  // {
  //   question: "Who are the creators of Sun Rebels?",
  //   answer:
  //     "<a href='https://incognant.com/'>https://incognant.com/</a> - we did! For those unaware, our team created a popular collection on Solana that has done over $30M in sales volume and holds the trophy for the highest single-day volume on Solana. That collection changed the game by giving out 100% of the royalties to the community and now we're ready to innovate again. ",
  // },
];

export const roadmapDatas = [
  {
    question: "What is SunRebels?",
    answer:
      "SunRebels is an exhilarating project by the rising star, SORRISO, that aims to revolutionize the XRPL NFT landscape by blending art, fashion, and design.<br /><br />The collection boasts over 450 unique, high-definition (4K resolution) hand-drawn traits, all seamlessly integrated with the power of the XRP Ledger.",
  },
  {
    question: "Who created SunRebels?",
    answer:
      "SunRebels is the creative masterpiece of SORRISO. Learn more about him on the desktop after clicking the <strong>SORRISO</strong> icon.<br /><br />After launching one of the largest projects in Solana's history, Meerkat Millionaires Country Club, Timon and Neptune have once again joined forces with SORRISO to bring his passion project to life on XRPL. This project showcases stunning art and a unique focus on fashion and design, setting a new standard in the NFT universe on Ripple.",
  },
  {
    question: "What are the plans for the project?",
    answer:
      "<strong>Scroll to read more...</strong><br /><br />While our primary focus is to amplify the extraordinary art of SORRISO and bolster his brand, we have some groundbreaking plans that we believe will redefine the XRP landscape.<br /><br />🎯 We aim to be the #1 gaming platform on XRP. Full stop.<br /><br />🎯 We aim to offer the most generous value proposition to our holders through becoming the #1 gaming platform. Rewards, raffles, and much more will be standard for SunRebels holders.&nbsp;<br /><br />🎯 We aim to develop a trait swapping platform that will unlock the potential of the vast array of traits currently associated with SunRebels. We will actively seek strategic partnerships with marketplaces to introduce this mechanism to the entire ecosystem.<br /><br />🎯 We will launch a social staking platform that will allow our holders to accumulate points for staking their PFP on twitter. These points will be directly redeemable for XRP, NFTs, Merch and more! This platform will also migrate into a SaaS available to other projects on XRP!<br /><br />🎯 As a SunRebels holder, you will have the chance to receive exclusive 1/1 drops by Sorriso! Some of the top art collectors on ETH are proud owners of his artwork!<br /><br />As we progress, we will continue to identify areas within the XRP ecosystem that align with the Rebels brand and require development. We are thrilled to be part of this exciting journey!",
  },
  {
    question: "Why Launch on XRP? I didn't even know XRP had NFTs!",
    answer:
      "XRP, an OG in the crypto world, has a fascinating history. Despite facing a brutal lawsuit from the SEC, the XRP chain continued to develop, securing international partnerships and enhancing on-chain functionality. Recently, the judge ruled in favor of XRP, greenlighting its continued development and implementation. We believe XRP has the chance to move back to #3 in crypto!<br /><br />NFTs were introduced on XRP in 2022, and a few projects have since started developing art and utilities for the community. However, there is still a need for impressive art and utility development. We see this as a unique opportunity to get in at the ground floor, rather than riding the hype wave of a new chain.<br /><br />Therefore, we've decided to showcase Sorriso's incredible art on XRP. We invite everyone to join us in pushing the boundaries of what's possible on XRP and introducing the broader NFT community to the potential benefits of a legally clear, highly developed, fast, and cost-effective chain. We are confident that we will set a new standard for art on XRP and continue to raise the bar for future builders.",
  },
];
