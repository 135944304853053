import styled from 'styled-components';

export const Win95Button = styled.button<{ disabled?: boolean }>`
  padding: 6px 12px;
  font-size: 14px;
  font-family: 'MS Sans Serif', Arial, sans-serif;
  color: #000000;
  background-color: #c0c0c0;
  border: 2px solid #ffffff;
  border-right-color: #808080;
  border-bottom-color: #808080;
  box-shadow: 1px 1px 0 0 #000000;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  outline: none;
  opacity: ${props => props.disabled ? 0.5 : 1};

  &:active {
    border: ${props => props.disabled ? '2px solid #ffffff' : '2px solid #808080'};
    border-right-color: ${props => props.disabled ? '#808080' : '#ffffff'};
    border-bottom-color: ${props => props.disabled ? '#808080' : '#ffffff'};
    box-shadow: ${props => props.disabled ? '1px 1px 0 0 #000000' : '-1px -1px 0 0 #000000'};
  }

  &:focus {
    outline: 1px dotted #000000;
    outline-offset: -4px;
  }
`;