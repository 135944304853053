import styled from "styled-components";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMobile } from "react-device-detect";
import * as Atom from "../atom";
import * as Display from "../atom/diplayWindows";

import { Windows } from "../_main/Windows";
// 'Applications'
import { Builder } from "./Builder";
import { About } from "./About";
import { Faq } from "./Faq";
import { Roadmap } from "./Roadmap";

// 'Folders'
import { VideoFolder } from "./Folders/Video";
import { ToolsFolder } from "./Folders/Tools";
import { TrashFolder } from "./Folders/Trash";

// Constant variables
import { VanityGenerator } from "../tools/VanityGenerator";
import WalletConnectButton from '../_main/WalletConnectButton';
import { rebelStakingAtom } from "../atom/rebelstaking";
import { displayRebelStaking } from "../atom/diplayWindows";
import { freecellAtom, displayFreecell } from "../atom/rebelgames";

// Add this new import
import { ProjectInfoFolder } from "../ProjectInfoFolder";
import Paint from '../home/Paint';
import { paintAtom, displayPaint } from '../atom/paint';
import { imageEditorAtom } from '../atom/imageEditor';
import ImageEditor from '../home/ImageEditor';

const Home = () => {
  const [builder, setBuilder] = useRecoilState(Atom.builderAtom);
  const [roadMapWindows, setRoadmap] = useRecoilState(Atom.roadmapAtom);
  const [faqWindow, setFaqWindow] = useRecoilState(Atom.faqAtom);
  const [aboutWindow, setAboutWindow] = useRecoilState(Atom.aboutAtom);
  const [videoFolderWindow, setVideoFolderWindow] = useRecoilState(Atom.videoFolderAtom);
  const [videoPlayerWindow, setVideoPlayerWindow] = useRecoilState(Atom.videoPlayer);
  const [toolsFolderWindow, setToolsFolderWindow] = useRecoilState(Atom.toolsFolderAtom);
  const [vanityToolWindow, setVanityTool] = useRecoilState(Atom.vanityGeneratorAtom);
  const [trashFolderWindow, setTrashFolderWindow] = useRecoilState(Atom.trashFolderAtom);
  const [rebelStaking, setRebelStaking] = useRecoilState(rebelStakingAtom);
  const [displayRebelStakingWindow, setDisplayRebelStakingWindow] = useRecoilState(displayRebelStaking);
  const [freecell, setFreecell] = useRecoilState(freecellAtom);
  const [displayFreecellWindow, setDisplayFreecellWindow] = useRecoilState(displayFreecell);

  // Add new state for ProjectInfo folder
  const [projectInfoFolderWindow, setProjectInfoFolderWindow] = useRecoilState(Atom.projectInfoFolderAtom);
  const isDisplayProjectInfoFolder = useRecoilValue(Display.displayProjectInfoFolder);

  const isDisplayFaq = useRecoilValue(Display.displayFaq);
  const isDisplayBuilder = useRecoilValue(Display.displayBuilder);
  const isDisplayRoadmap = useRecoilValue(Display.displayRoadmap);
  const isDisplayAbout = useRecoilValue(Display.displayAbout);
  const isDisplayVideoFolder = useRecoilValue(Display.displayVideoFolder);
  const isDisplayVideoPlayer = useRecoilValue(Display.displayVideoPlayer);
  const isDisplayToolsFolder = useRecoilValue(Display.displayToolsFolder);
  const isDisplayTrashFolder = useRecoilValue(Display.displayTrashFolder);
  const isDisplayVanityTool = useRecoilValue(Display.displayVanityGeneratorTool);

  // Add a new state for the staking iframe visibility
  const [showStakingIframe, setShowStakingIframe] = useState(false);
  const [paint, setPaint] = useRecoilState(paintAtom);
  const [displayPaintWindow] = useRecoilState(displayPaint);
  const [imageEditor, setImageEditor] = useRecoilState(imageEditorAtom);

  useEffect(() => {
    if (isMobile) {
      setAboutWindow(1);
      setFaqWindow(1);
      setRoadmap(1);
      setBuilder(1);
      setVideoPlayerWindow(1);
      setTrashFolderWindow(1);
      setToolsFolderWindow(1);
      setVanityTool(1);
    }
  }, [
    setAboutWindow,
    setFaqWindow,
    setRoadmap,
    setBuilder,
    setVideoPlayerWindow,
    setTrashFolderWindow,
    setToolsFolderWindow,
    setVanityTool,
  ]);

  const handleRebelStakingClick = () => {
    setRebelStaking(1);
    setDisplayRebelStakingWindow(true);
  };

  const handleFreecellClick = () => {
    setFreecell(1);
    setDisplayFreecellWindow(true);
  };

  return (
    <HomeContainer overFlow={isMobile}>
      <ContainerIconRight isMobile={isMobile}>
        {/* Trash Icon */}
        <IconWindows
          className="handle"
          onClick={() => {
            setTrashFolderWindow(1);
          }}
        >
          <Img src="icons/trash.png"></Img>
          <Label>Trash</Label>
        </IconWindows>
        
        {/* Project Info Folder */}
        <IconWindows
          className="handle"
          onClick={() => {
            setProjectInfoFolderWindow(1);
          }}
        >
          <Img src="icons/folder.png"></Img>
          <Label>Project Info</Label>
        </IconWindows>

        {/* Video Folder */}
        <IconWindows
          className="handle"
          onClick={() => {
            setVideoFolderWindow(1);
          }}
        >
          <Img src="icons/folder.png"></Img>
          <Label>Video</Label>
        </IconWindows>

        {/* Tools Folder */}
        <IconWindows
          className="handle"
          onClick={() => {
            setToolsFolderWindow(1);
          }}
        >
          <Img src="icons/folder.png"></Img>
          <Label>Tools</Label>
        </IconWindows>

        {/* RebelBuilder.exe - Moved beneath Tools folder */}
        <IconWindows
          className="handle"
          onClick={() => {
            setBuilder(1);
          }}
        >
          <Img src="icons/windows98-icons/png/tools_gear-0.png"></Img>
          <Label>RebelBuilder.exe</Label>
        </IconWindows>
        
        {/* Rebel Staking Icon */}
        <IconWindows
          className="handle"
          onClick={handleRebelStakingClick}
        >
          <Img src="/icons/floppy_drive_3_5_cool-3.png"></Img>
          <Label>Rebel Staking</Label>
        </IconWindows>

        {/* Rebel Games Icon */}
        <IconWindows
          className="handle"
          onClick={handleFreecellClick}
        >
          <Img src="icons/freecell.png" alt="Rebel Games" />
          <Label>Rebel Games</Label>
        </IconWindows>

        {/* Paint Icon */}
        <IconWindows
          className="handle"
          onClick={() => setPaint(1)}
        >
          <Img src="icons/paint.png"></Img>
          <Label>Paint</Label>
        </IconWindows>
      </ContainerIconRight>

      <ContainerIcon isMobile={isMobile}>
        {/* Connect Wallet - Moved above XRPL_Guide */}
        <IconWindows>
          <WalletConnectButton />
        </IconWindows>

        {/* XRPL guide document */}
        <IconWindows
          className="handle"
          onClick={() => setFaqWindow(1)}
        >
          <Img src="icons/faq.png"></Img>
          <Label>XRPL_Guide.docx</Label>
        </IconWindows>
      </ContainerIcon>
      <>
        {!!roadMapWindows && (
          <Windows
            setClose={setRoadmap}
            title={"SunRebels"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "auto 5% auto auto"}
            isDisplay={isMobile ? true : isDisplayRoadmap}
            isPhone={isMobile}
          >
            <Roadmap />
          </Windows>
        )}
        {!!builder && (
          <Windows
            setClose={setBuilder}
            title={"RebelBuilder.exe"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={isMobile ? true : isDisplayBuilder}
            isPhone={isMobile}
          >
            <Builder />
          </Windows>
        )}
        {!!faqWindow && (
          <Windows
            setClose={setFaqWindow}
            title={"XRPL Guide.docx"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayFaq}
            isPhone={isMobile}
          >
            <Faq />
          </Windows>
        )}
        {!!aboutWindow && (
          <Windows
            setClose={setAboutWindow}
            title={"SORRISO"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayAbout}
            isPhone={isMobile}
          >
            <About />
          </Windows>
        )}
        {!!videoFolderWindow && (
          <Windows
            setClose={setVideoFolderWindow}
            title={"Video"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? false : isDisplayVideoFolder}
            isPhone={isMobile}
          >
            <VideoFolder />
          </Windows>
        )}
        {!!videoPlayerWindow && (
          <Windows
            setClose={setVideoPlayerWindow}
            title={"Video player"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "auto auto auto auto"}
            isDisplay={isMobile ? true : isDisplayVideoPlayer}
            isPhone={isMobile}
          >
            <iframe
              width={isMobile ? "360px" : "600px"}
              height="360px"
              style={{ padding: "10px", maxWidth: "80vw" }}
              src="https://www.youtube.com/embed/kemivUKb4f4?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Windows>
        )}
        {!!trashFolderWindow && (
          <Windows
            setClose={setTrashFolderWindow}
            title={"Trash"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="50px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayTrashFolder}
            isPhone={isMobile}
          >
            <TrashFolder />
          </Windows>
        )}
        {!!toolsFolderWindow && (
          <Windows
            setClose={setToolsFolderWindow}
            title={"Tools"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "60px auto auto 40px"}  // Adjusted margin
            isDisplay={isMobile ? true : isDisplayToolsFolder}
            isPhone={isMobile}
          >
            <ToolsFolder />
          </Windows>
        )}
        {!!vanityToolWindow && (
          <Windows
            setClose={setVanityTool}
            title={"XRP Vanity Address Generator"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="auto"
            maxWidth="auto"
            minHeight="auto"
            maxHeight="auto%"
            isResizable={true}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={isMobile ? true : isDisplayVanityTool}
            isPhone={isMobile}
          >
            <VanityGenerator />
          </Windows>
        )}
        
        {showStakingIframe && (
          <Windows
            setClose={() => setShowStakingIframe(false)}
            title={"Rebel Staking"}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={true}
            isPhone={isMobile}
          >
            <iframe
              src="https://stake.sunrebels.io"
              style={{
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              title="Rebel Staking"
            />
          </Windows>
        )}
        {rebelStaking && displayRebelStakingWindow && (
          <Windows
            setClose={() => {
              setRebelStaking(0);
              setDisplayRebelStakingWindow(false);
            }}
            title={"Rebel Staking"}
            width="1000px"  // Optional: Set a default width
            height="800px"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={true}
            isPhone={isMobile}
          >
            <iframe
              src="https://stake.sunrebels.io"
              style={{
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              title="Rebel Staking"
            />
          </Windows>
        )}
        {freecell && displayFreecellWindow && (
          <Windows
            setClose={() => {
              setFreecell(0);
              setDisplayFreecellWindow(false);
            }}
            title={"Rebel Games"}
            width="1000px"  // Optional: Set a default width
            height="800px"
            minWidth="100px"
            maxWidth="100%"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={true}
            isPhone={isMobile}
          >
            <iframe
              src="https://rebelgames.io"
              style={{
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              title="Rebel Games"
            />
          </Windows>
        )}
        {!!projectInfoFolderWindow && (
          <Windows
            setClose={setProjectInfoFolderWindow}
            title={"Project Info"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="100px"
            maxWidth="80vw"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "90px auto auto 60px"}  // Adjusted margin
            isDisplay={isMobile ? true : isDisplayProjectInfoFolder}
            isPhone={isMobile}
          >
            <ProjectInfoFolder />
          </Windows>
        )}
        {!!paint && (
          <Windows
            setClose={() => setPaint(0)}
            title={"Paint"}
            width="fit-content"  // Optional: Set a default width
            height="fit-content"
            minWidth="400px"
            maxWidth="80vw"
            minHeight="300px"
            maxHeight="100%"
            isResizable={true}
            margin={isMobile ? "" : "90px auto auto 60px"}
            isDisplay={isMobile ? true : displayPaintWindow}
            isPhone={isMobile}
          >
            <Paint />
          </Windows>
        )}
        {imageEditor.isOpen && (
          <Windows
            setClose={() => setImageEditor({ ...imageEditor, isOpen: false })}
            title="Image Editor"
            width="500px"  // Optional: Set a default width
            height="500px"
            minWidth="100px"
            maxWidth="100%"
            minHeight="100px"
            maxHeight="100%"
            isResizable={true}
            //margin="90px auto auto 60px"
            isDisplay={true}
            isPhone={false}
          >
            <ImageEditor />
          </Windows>
        )}
      </>
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div<{ overFlow: Boolean }>`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  height: 80vh;
  z-index: 0;
  overflow: ${(props) => props.overFlow && "auto"};
  ${({ overFlow }) =>
    overFlow &&
    `
    margin-top: 20px;
    margin-bottom: 20vh;
    display: flex;
    flex-direction: column;
    height: fit-content;
  `}
  @media (max-width: 415px) {
    margin-top: 20px;
    margin-bottom: 20vh;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
`;

const ContainerIcon = styled.div<{ isMobile: Boolean }>`
  display: flex;
  position: fixed;
  right: 20px;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile &&
    `
    display: none
  `};
`;

const ContainerIconRight = styled(ContainerIcon)`
  position: fixed;
  left: 20px;
  flex-direction: column;
`;

const IconWindows = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 63px;
  height: 63px;
  margin: 0px 30px 30px 30px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
`;

const Img = styled.img`
  width: 80%;
  height: 80%;
`;

const Label = styled.div`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;
