import { atom } from "recoil";

export const paintAtom = atom({
  key: "paintAtom",
  default: 0,
});

export const displayPaint = atom({
  key: "displayPaint",
  default: true,
});