import { atom } from 'recoil';

export const twitterAtom = atom({
  key: 'twitterAtom',
  default: 0,
});

export const discordAtom = atom({
  key: 'discordAtom',
  default: 0,
});