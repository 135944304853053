import { atom } from "recoil";

interface ImageEditorState {
  image: string | null;
  isOpen: boolean;
}

export const imageEditorAtom = atom<ImageEditorState>({
  key: "imageEditorAtom",
  default: {
    image: null,
    isOpen: false,
  },
});