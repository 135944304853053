import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useRecoilState } from "recoil";
import * as Atom from "../atom";
import * as display from "../atom/diplayWindows";
import { twitterUrl, discordUrl } from "../../constants";
import { rebelStakingAtom } from "../atom/rebelstaking";
import { freecellAtom, displayFreecell } from "../atom/rebelgames";
import { paintAtom, displayPaint } from '../atom/paint';

export default function Footer() {
  const [menuWindows, setMenuWindows] = useState(false);
  const [date, setDate] = useState(new Date());
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

  const [builder, setPreview3d] = useRecoilState(Atom.builderAtom);
  const [about, setAbout] = useRecoilState(Atom.aboutAtom);
  const [roadmap, setRoadmap] = useRecoilState(Atom.roadmapAtom);
  const [vanityGenerator, setVanityGenerator] = useRecoilState(Atom.vanityGeneratorAtom);
  const [videoFolder] = useRecoilState(Atom.videoFolderAtom);
  const [videoPlayer, setVideoPlayer] = useRecoilState(Atom.videoPlayer);
  const [trashFolder] = useRecoilState(Atom.trashFolderAtom);
  const [toolsFolder] = useRecoilState(Atom.toolsFolderAtom);
  const [faq, setFaqWindow] = useRecoilState(Atom.faqAtom);
  const [rebelStaking, setRebelStaking] = useRecoilState(rebelStakingAtom);
  const [freecell, setFreecell] = useRecoilState(freecellAtom);
  const [projectInfoFolder] = useRecoilState(Atom.projectInfoFolderAtom);
  const [paint, setPaint] = useRecoilState(paintAtom);
  const [displayPaintWindow, setDisplayPaintWindow] = useRecoilState(displayPaint);

  const [displayBuilder, setDisplayBuilder] = useRecoilState(display.displayBuilder);
  const [displayVanityGenerator, setDisplayVanityGenerator] = useRecoilState(display.displayVanityGeneratorTool);
  const [displayFaq, setDisplayFaq] = useRecoilState(display.displayFaq);
  const [displayAbout, setDisplayAbout] = useRecoilState(display.displayAbout);
  const [displayRoadmap, setDisplayRoadmap] = useRecoilState(display.displayRoadmap);
  const [displayVideoFolder, setDisplayVideoFolder] = useRecoilState(display.displayVideoFolder);
  const [displayVideoPlayer, setDisplayVideoPlayer] = useRecoilState(display.displayVideoPlayer);
  const [displayTrashFolder, setDisplayTrashFolder] = useRecoilState(display.displayTrashFolder);
  const [displayToolsFolder, setDisplayToolsFolder] = useRecoilState(display.displayToolsFolder);
  const [displayRebelStaking, setDisplayRebelStaking] = useRecoilState(display.displayRebelStaking);
  const [displayFreecellWindow, setDisplayFreecellWindow] = useRecoilState(displayFreecell);
  const [displayProjectInfoFolder, setDisplayProjectInfoFolder] = useRecoilState(display.displayProjectInfoFolder);

  const ref = useRef<HTMLDivElement>(null);
  const isPhone = window.innerWidth < 415;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && menuWindows) {
        setMenuWindows(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuWindows]);

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleMenuItemHover = (menuItem: string) => {
    setActiveSubmenu(menuItem);
  };

  const handleMenuItemLeave = () => {
    setActiveSubmenu(null);
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    setMenuWindows(false);
  };

  const openExternalLink = (url: string) => {
    window.open(url, '_blank');
    setMenuWindows(false);
  };

  return (
    <FooterContainer>
      <Start onClick={() => setMenuWindows(!menuWindows)}>
        <ImgStart src="icons/sunlogo-64px.png" alt="Start" />
        Start
      </Start>

      {builder ? (
        <Start onClick={() => setDisplayBuilder(!displayBuilder)}>
          <ImgStart src="icons/rebel.png" />
          RebelBuilder.exe
        </Start>
      ) : null}
      {roadmap ? (
        <Start onClick={() => setDisplayRoadmap(!displayRoadmap)}>
          <ImgStart src="icons/roadmap.png" />
          SunRebels
        </Start>
      ) : null}
      {faq ? (
        <Start onClick={() => setDisplayFaq(!displayFaq)}>
          <ImgStart src="icons/faq.png" />
          XRPL Guide.docx
        </Start>
      ) : null}
      {about ? (
        <Start onClick={() => setDisplayAbout(!displayAbout)}>
          <ImgStart src="icons/about.png" />
          SORRISO
        </Start>
      ) : null}
      {videoFolder ? (
        <Start onClick={() => setDisplayVideoFolder(!displayVideoFolder)}>
          <ImgStart src="icons/folder.png" />
          Video
        </Start>
      ) : null}
      {videoPlayer ? (
        <Start onClick={() => setDisplayVideoPlayer(!displayVideoPlayer)}>
          <ImgStart src="icons/videoPlayer.png" />
          Video Player
        </Start>
      ) : null}
      {trashFolder ? (
        <Start onClick={() => setDisplayTrashFolder(!displayTrashFolder)}>
          <ImgStart src="icons/trash.png" />
          Trash
        </Start>
      ) : null}
      {toolsFolder ? (
        <Start onClick={() => setDisplayToolsFolder(!displayToolsFolder)}>
          <ImgStart src="icons/folder.png" />
          Tools
        </Start>
      ) : null}
      {vanityGenerator ? (
        <Start onClick={() => setDisplayVanityGenerator(!displayVanityGenerator)}>
          <ImgStart src="/icons/windows98-icons/png/key_padlock_help.png" />
          Vanity Generator
        </Start>
      ) : null}
      {rebelStaking ? (
        <Start onClick={() => setDisplayRebelStaking(!displayRebelStaking)}>
          <ImgStart src="icons/rebel.png" />
          Rebel Staking
        </Start>
      ) : null}
      {freecell ? (
        <Start onClick={() => setDisplayFreecellWindow(!displayFreecellWindow)}>
          <ImgStart src="icons/freecell.png" />
          Rebel Games
        </Start>
      ) : null}
      {projectInfoFolder ? (
        <Start onClick={() => setDisplayProjectInfoFolder(!displayProjectInfoFolder)}>
          <ImgStart src="icons/folder.png" />
          Project Info
        </Start>
      ) : null}
      {paint ? (
        <Start onClick={() => setDisplayPaintWindow(!displayPaintWindow)}>
          <ImgStart src="icons/paint.png" />
          Paint
        </Start>
      ) : null}

      <StartMenu ref={ref} isActive={menuWindows}>
        <MenuContent>
          <MenuItem
            onMouseEnter={() => handleMenuItemHover('ProjectInfo')}
            onMouseLeave={handleMenuItemLeave}
          >
            <MenuItemContent>
              <MenuItemIcon src="icons/folder.png" alt="Project Info" />
              Project Info
              <ArrowIcon>▶</ArrowIcon>
            </MenuItemContent>
            {activeSubmenu === 'ProjectInfo' && (
              <Submenu>
                <SubmenuItem onClick={() => handleMenuItemClick(() => openExternalLink(twitterUrl))}>
                  <MenuItemIcon src="icons/twitter.png" alt="Twitter" />
                  Twitter
                </SubmenuItem>
                <SubmenuItem onClick={() => handleMenuItemClick(() => openExternalLink(discordUrl))}>
                  <MenuItemIcon src="icons/discord.png" alt="Discord" />
                  Discord
                </SubmenuItem>
                <SubmenuItem onClick={() => handleMenuItemClick(() => setRoadmap(1))}>
                  <MenuItemIcon src="/icons/windows98-icons/png/tip.png" alt="About Sunrebels" />
                  About Sunrebels
                </SubmenuItem>
                <SubmenuItem onClick={() => handleMenuItemClick(() => setAbout(1))}>
                  <MenuItemIcon src="icons/about.png" alt="About Sorriso" />
                  About Sorriso
                </SubmenuItem>
              </Submenu>
            )}
          </MenuItem>

          <MenuItem
            onMouseEnter={() => handleMenuItemHover('Video')}
            onMouseLeave={handleMenuItemLeave}
          >
            <MenuItemContent>
              <MenuItemIcon src="icons/folder.png" alt="Video" />
              Video
              <ArrowIcon>▶</ArrowIcon>
            </MenuItemContent>
            {activeSubmenu === 'Video' && (
              <Submenu>
                <SubmenuItem onClick={() => handleMenuItemClick(() => setVideoPlayer(1))}>
                  <MenuItemIcon src="icons/videoPlayer.png" alt="Video Player" />
                  Video_01.mp4
                </SubmenuItem>
              </Submenu>
            )}
          </MenuItem>
          
          <MenuItem
            onMouseEnter={() => handleMenuItemHover('Tools')}
            onMouseLeave={handleMenuItemLeave}
          >
            <MenuItemContent>
              <MenuItemIcon src="icons/folder.png" alt="Tools" />
              Tools
              <ArrowIcon>▶</ArrowIcon>
            </MenuItemContent>
            {activeSubmenu === 'Tools' && (
              <Submenu>
                <SubmenuItem onClick={() => handleMenuItemClick(() => setVanityGenerator(1))}>
                  <MenuItemIcon src="icons/windows98-icons/png/key_padlock_help.png" alt="Vanity Address Generator" />
                  Vanity Address Generator
                </SubmenuItem>
              </Submenu>
            )}
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(() => setFaqWindow(1))}>
            <MenuItemContent>
              <MenuItemIcon src="icons/faq.png" alt="XRPL Guide" />
              XRPL Guide.docx
            </MenuItemContent>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(() => setPreview3d(1))}>
            <MenuItemContent>
              <MenuItemIcon src="icons/windows98-icons/png/tools_gear-0.png" alt="RebelBuilder" />
              RebelBuilder.exe
            </MenuItemContent>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(() => setRebelStaking(1))}>
            <MenuItemContent>
              <MenuItemIcon src="/icons/floppy_drive_3_5_cool-3.png" alt="Rebel Staking" />
              Rebel Staking
            </MenuItemContent>
          </MenuItem>
          <MenuItem onClick={() => {
            if (!isPhone) {
              handleMenuItemClick(() => {
                setFreecell(1);
                setDisplayFreecellWindow(true);
              });
            }
          }}>
            <MenuItemContent>
              <MenuItemIcon src="icons/freecell.png" alt="Rebel Games" />
              Rebel Games
            </MenuItemContent>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(() => setPaint(1))}>
            <MenuItemContent>
              <MenuItemIcon src="icons/paint.png" alt="Paint" />
              Paint
            </MenuItemContent>
          </MenuItem>
        </MenuContent>
      </StartMenu>

      <DateWindows>
        <DateDiv>
          <img
            src="https://images-ext-1.discordapp.net/external/3YjBagcEkLXbTBwhJNcR3vWdkc0WuyhTtItme2zWqrQ/%3Fsize%3D96%26quality%3Dlossless/https/cdn.discordapp.com/emojis/811261551981232218.gif"
            alt="pepe"
            style={{ height: "100%", marginRight: "10px" }}
          /> 
          {moment(date).format("h:mm a")}
        </DateDiv>
      </DateWindows>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  font-size: 8px;
  height: 38px;
  background-color: #c0c0c0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #404040;
  display: flex;
  align-items: center;
  z-index: 11;
  @media (max-width: 415px) {
    display: none;
  }
`;

const Start = styled.button`
  background-size: 17px 15px;
  text-align: right;
  font-weight: bolder;
  font-size: 16px;
  width: fit-content;
  display: flex;
  cursor: default;
  height: 80%;
  background: #c3c6cd;
  border-top: 2px solid #deebf3;
  border-left: 2px solid #deebf3;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  user-select: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  padding-left: 5px;
  &:active {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid;
    border-bottom: 2px solid white;
  }
`;

const StartMenu = styled.div<{ isActive: boolean }>`
  background: #c0c0c0;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
  width: 250px;
  display: ${(props) => (props.isActive ? "block" : "none")};
  position: absolute;
  bottom: 38px;
  left: 0;
  z-index: 1000;
  padding: 2px;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #808080;
`;

const MenuItem = styled.div`
  position: relative;
  border-bottom: 1px solid #808080;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #000080;
    color: white;
  }
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-family: 'MS Sans Serif', Arial, sans-serif;
`;

const MenuItemIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const ArrowIcon = styled.span`
  margin-left: auto;
  font-size: 12px;
`;

const Submenu = styled.div`
  position: absolute;
  top: -2px;
  left: 100%;
  background-color: #c0c0c0;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
  min-width: 200px;
  padding: 2px;
`;

const SubmenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-family: 'MS Sans Serif', Arial, sans-serif;
  border-bottom: 1px solid #808080;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #000080;
    color: white;
  }
`;

const DateWindows = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: black;
  position: absolute;
  right: 2px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const ImgStart = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const DateDiv = styled.div`
  padding: 0 5px;
  display: flex;
  height: 72%;
  align-items: center;
  background: #c0c0c0;
  border-top: 1px solid #808080;
  border-left: 1px solid #808080;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
`;