import { atom } from "recoil";

export const aboutAtom = atom({
  key: "aboutAtom",
  default: 0,
});

// Keep any other existing atoms in this file
export const aboutSunrebelsAtom = atom({
  key: "aboutSunrebelsAtom",
  default: 0,
});

export const aboutSorrisoAtom = atom({
  key: "aboutSorrisoAtom",
  default: 0,
});
